import { bagelSocket } from "@/helpers/socket";

export default {
  methods: {
    saveButtonTimer(refer, value) {
      setTimeout(() => {
        this[refer] = value;
      }, 1500);
    },
  },
  async created() {
    bagelSocket.setUpChannel(this);

    this.channel = this.$.appContext.config.globalProperties.channel;
    this.channelListenersUp =
    this.$.appContext.config.globalProperties.channelListenersUp;


    if (this.$store.state.all == undefined || !this.$store.state.all.length) { //removed cart 
      await this.$store.dispatch("getAllData");
    }
    /* this should retrieve all changes to the cart from local storage */
    const cart = window.localStorage.getItem("cart");
    if (cart) {
      try{
      this.$store.dispatch("initialiseCartFromLocalStorage", JSON.parse(cart));
      }catch(error){
        console.log(error);
      }
    }
    const webO = window.localStorage.getItem("webOrder");
    if (webO) {
      this.$store.dispatch("updateWebOrder", JSON.parse(webO));
    }

    /* this should save all changes to the cart to local storage */
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      window.localStorage.setItem(
        "cart",
        JSON.stringify({
          products: state.cart.added,
          payments: state.cart.payments,
        })
      );

      window.localStorage.setItem(
        "settings",
        JSON.stringify({
          settings: state.localSettings,
        })
      );
    });
    if (!this.channelListenersUp) {
      /* handle modifierset updates */
      this.channel.on("document:updated", (doc) => {
        if (doc._id.match(/^modifierset:/)) {
          this.$store.dispatch("updateModifierSet", doc);
        }

        if (doc._id.match(/^product:/)) {
          this.$store.dispatch("updateProduct", doc);
        }

        if (doc._id.match(/^category:/)) {
          this.$store.dispatch("updateCategory", doc);
        }

        if (doc._id.match(/^discount:/)) {
          this.$store.dispatch("updateDiscount", doc);
        }
      });

      /* handle a new order, to update the order history */
      this.channel.on("account:doc", (doc) => {
        this.$store.dispatch("addAccountDoc", doc);
      });

      this.channel.on("voucher:doc", (doc) => {
        this.$store.dispatch("addVoucherDoc", doc);
      });

      this.channel.on("order:new", (doc) => {
        this.$store.dispatch("prependHistoryNew", doc.rows[0]);
        /***if(this.$store.state.cart.user){
          console.log('user is logged in', doc.rows[0])
          }***/
      });

      this.channel.on("order:updated", (doc) => {
        this.$store.dispatch("findAndUpdateHistoryDoc", doc.rows[0]);
      });

      this.channel.on("company:updated", (doc) => {
        this.$store.dispatch("updateCompanySettings", doc);
      });

      this.channel.on("modifier:quantity", (mod) => {
        this.$store.dispatch("checkCartModifier", mod);
      });

      this.channel.on("document:deleted", (doc) => {
        if (doc.id.match(/^modifierset:/)) {
          this.$store.dispatch("deleteModifierSet", { id: doc.id });
        }

        if (doc.id.match(/^category:/)) {
          this.$store.dispatch("deleteCategory", { id: doc.id });
        }

        if (doc.id.match(/^discount:/)) {
          this.$store.dispatch("deleteDiscount", { id: doc.id });
        }
      });

      this.channel.on("item:completed", (doc) => {
        console.log("item completed", doc);
        this.$store.dispatch("updateItem", {hash: doc.pk});
      });

      this.channel.on("item:started", (doc) => {
        console.log("item start", doc);
        this.$store.dispatch("startWebItem", doc);
      });
      
      this.channel.on("unknown", (intent) => {
        this.$store.dispatch("setStripePaymentIntent", intent);
      });

      this.channel.on("company:site:closed", (doc) => {
        this.$store.dispatch("setSiteClosed", doc.status);
        
      });

      this.channel.on("station:updated", (doc) => {
        console.log("station updated", doc);
        this.$store.dispatch("getAllData");
      });

      this.$.appContext.config.globalProperties.channelListenersUp = true;
    }

    // /* handle product updates */
    // this.channel.on('document:updated', (doc) => {
    //     console.log(doc);
    //     let id = doc._id;
    //     if ( id.match(/^product:/) ) {
    //         this.$store.dispatch('updateProduct', doc)
    //     }
    // });
    //
  },
};
